import { Link, useNavigate } from 'react-router-dom'
import { useTheme } from '../context/ThemeProvider'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

function Missing() {
  const navigate = useNavigate()
  const theme = useTheme();
  const style = {
    backgroundColor: theme.backgroundColor,
    color: theme.textColor,
  };

  return (
    <section className='w-screen h-screen py-16'>
      <div className='main-bg'>&nbsp;</div>
      <div className='flex items-center flex-col z-10 relative'>
        <div className='flex'>
        </div>
        <div className='bg-white rounded-xl shadow-2xl py-10 mt-8 px-8 w-full flex flex-col items-center' style={{ maxWidth: 500 }}>
          <h1 className='text-3xl font-bold text-center' style={{color: style.color}}>404 Page Not Found</h1>
          <h2 className='text-center my-8'>
            Uh oh! We can't seem to find the page you're looking for. Try going back to the previous page or see our
            <Link to='/help'>Help Center</Link> for more information.
          </h2>
          <button onClick={() => navigate(-1, { replace: true })} className="button-inverted" style={{borderColor: style.backgroundColor}}>
            <span className="" style={{backgroundColor: style.backgroundColor}}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </span>
            <span className="" style={{ color:  style.color}}>Go Back</span>
          </button>
        </div>
      </div>
    </section>
  )
}

export default Missing
