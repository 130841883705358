import React from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';

const RenderHtml = ({ htmlString }) => {
  const sanitizedHtml = DOMPurify.sanitize(htmlString);

  return (
    <div className='mt-4'
      dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
    />
  );
};

RenderHtml.propTypes = {
  htmlString: PropTypes.string.isRequired,
};

export default RenderHtml;
