
import RenderHtml from './RenderHtml';
function Footer({theme}) {
    return (
      <footer className="bg-slate-200 w-full mt-16" aria-labelledby="footer-heading">
        <div className="mx-auto max-w-7xl px-6 py-8">
          <div className="flex flex-col lg:flex-row justify-between">
            <div className="pr-12">
              <div className="mb-6 md:mb-0">
                <div href="/" className="flex items-center">
                  <img src={theme.logo.url} className="h-12 me-3" alt={theme.logo.alt} />
                </div>
                <RenderHtml htmlString={theme.footer.top}/>
              </div>
          </div>
  
            <div className="">
              <h3 className="text-sm font-semibold leading-6 text-gray-700 mt-4">
                Contact Us
              </h3>
              <a
                href={theme.phone_number.link}
                className="flex items-center space-x-2 text-gray-700 mt-4 font-medium text-sm hover:underline"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-5 h-5 text-nav"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
                  />
                </svg>
                <span>{theme.phone_number.value}</span>
              </a>
              <a href={theme.website}
                rel="noreferrer"
                target="_blank"
                className="flex items-center space-x-2 text-gray-700 mt-4 font-medium text-sm hover:underline"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-5 h-5 text-nav"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418"
                  />
                </svg>
                <span>Website</span>
              </a>
            </div>
          </div>
          <div className="mt-6 border-t border-gray-900/10 py-2 text-sm flex flex-col justify-center items-center sm:flex-row sm:items-start sm:justify-between  ">
            <div className=" text-gray-500 md:mt-0">
              <RenderHtml htmlString={theme.footer.bottom}/>
            </div>
            <div className=" text-gray-500">
              <a
                href={theme.privacy}
                className="hover:underline mt-2 sm:mt-0 block"
              >
                Privacy Notice
              </a>
            </div>
          </div>
        </div>
      </footer>
    )
  }
  
  export default Footer
  
