import { createContext, useState } from 'react'

const AuthContext = createContext({})

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({})
  const [showLoader, setShowLoader] = useState(false)
  const [showInfo, setShowInfo] = useState()
  const [showUpdate, setShowUpdate] = useState(false)
  const [notNow, setNotNow] = useState(false)

  const createInfo = (type, message, timeout = 6000) => {
    setShowInfo({ type, message, show: 'show' })
    setTimeout(() => {
      setShowInfo({})
    }, timeout)
  }

  const adminUsers = ['Super User', 'Admin']
  const admin = auth?.token ? true : false;
  // const [persist, setPersist] = useState(JSON.parse(localStorage.getItem('persist')) || true)

  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
        admin,
        showLoader,
        setShowLoader,
        showInfo,
        createInfo,
        showUpdate,
        setShowUpdate,
        notNow,
        setNotNow,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContext
