import React, { useState, useEffect } from 'react';
import '../viewRequest.css'
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleDown, faChevronCircleUp, faCircleXmark, faDownload, faEye, faFile } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import useAxiosPrivate from '../hooks/useAxiosPrivate'

const ViewRequest = ({ isOpen, onClose, viewingRequest }) => {
  const [activityLogItems, setActivityLogItems] = useState(null);
  const [requestItems, setRequestItems] = useState(null);
  const [isAccordionOpen, setAccordionOpen] = useState(false);
  const axios = useAxiosPrivate()

  useEffect(() => {
    if (viewingRequest?.activity_log) {
      setActivityLogItems(JSON.parse(viewingRequest.activity_log));
    }
    if (viewingRequest?.request_information) {
      setRequestItems(JSON.parse(viewingRequest.request_information));
    }
  }, [viewingRequest]);

  const toggleAccordion = () => {
    setAccordionOpen(!isAccordionOpen);
  };

  const statusClass = (status) => {
    if (typeof status !== 'string') {
      return 'bg-gray-200 text-gray-800';
    }

    switch (status.toLowerCase()) {
      case 'form completed - awaiting result':
      case 'no barrier to work':
      case 'no action required':
        return 'bg-green-200 text-green-800 border-green-400';
      case 'awaiting form completion':
      case 'referral needed':
      case 'in progress':
        return 'bg-amber-200 text-amber-800 border-amber-400';
      case 'closed':
        return 'bg-rose-200 text-rose-800 border-rose-400';
      default:
        if (status.toLowerCase().startsWith('form completed -')) {
          return 'bg-green-200 text-green-800 border-green-400';
        } else {
          return 'bg-gray-200 text-gray-800';
        }
    }
  };

  const handleDownload = async () => {
    try {
      let fileName = requestItems.filename.replace('/pdf/oh-result/', '');
      window.open(`${process.env.REACT_APP_KIS}/download-decrypted-file/` + fileName, '_blank').focus();
    } catch (error) {
      console.error('Error downloading the file', error);
    }
  };

  const handleView = async () => {
    try {
      const response = await axios.post('view-decrypted-file', {
        params: { filePath: requestItems.filename},
        responseType: 'blob', 
      });

      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      window.open(fileURL, '_blank');
    } catch (error) {
      console.error('Error viewing the file', error);
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-[100]">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition duration-500 ease-in-out data-[closed]:opacity-0"
      />
      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex w-1/3 pl-10">
            <DialogPanel
              transition
              className="pointer-events-auto w-screen transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
            >
              <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                  <div className="bg-[#02ADAD] px-4 py-6 sm:px-6">
                    <div className="flex items-center justify-between">
                      <DialogTitle className="text-xl font-semibold leading-6 text-white">
                        View Support Request Status
                      </DialogTitle>
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          className="relative text-teal-100 hover:text-white"
                          onClick={onClose}
                        >
                          <span className="absolute -inset-2.5" />
                          <span className="sr-only">Close panel</span>
                          <FontAwesomeIcon icon={faCircleXmark} className="h-6 w-6 focus:border-none" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-1 flex-col justify-between">
                    <div className="divide-y divide-gray-200 px-4 sm:px-6">
                      <div className="space-y-6 pb-5 pt-6">
                        <div className="space-y-2">
                          <p><strong>Reference: </strong> {viewingRequest?.reference || ''}</p>
                          <p><strong>Title: </strong> Occupational Health Query </p>
                          <p><strong>Description: </strong> {viewingRequest?.description || 'N/A'}</p>
                          <p><strong>Status: </strong>
                            <span className={`${statusClass(viewingRequest?.request_status)} rounded-full px-2 py-1 capitalize font-medium text-sm`}>
                              {viewingRequest?.request_status || ''}
                            </span>
                          </p>
                          <p className="text-wrap">
                            <strong>Additional Information: </strong>
                            {viewingRequest?.request_information ? (
                              Object.entries(JSON.parse(viewingRequest.request_information).staff_details).map(([key, value]) => (
                                <div key={key} className='ml-4'>
                                  <strong className='capitalize'>{key.replace(/_/g, ' ')}:</strong>{' '}
                                  {key.toLowerCase().includes('date') && value ? moment(value).format('DD/MM/YYYY') : value}
                                </div>
                              ))
                            ) : (
                              'Not Found'
                            )}
                          </p>
                          <div>
                            <p className="mb-4 flex items-center">
                              <strong>Activity Log</strong>
                              <button onClick={toggleAccordion} className="ml-2 text-indigo-900 hover:underline">
                                <FontAwesomeIcon icon={isAccordionOpen ? faChevronCircleUp : faChevronCircleDown} className="h-4 w-4 focus:border-none" />
                              </button>
                            </p>
                            {isAccordionOpen && (
                              <div className="pl-6">
                                {activityLogItems && (Array.isArray(activityLogItems) ? activityLogItems.length > 0 : Object.keys(activityLogItems).length > 0) ? (
                                  <ol className="relative border-s">
                                    {(Array.isArray(activityLogItems) ? activityLogItems : [activityLogItems]).map((log, index) => (
                                      <li className="mb-6 flex items-center" key={index}>
                                        <span className="absolute flex items-center justify-center w-5 h-5 bg-[#02ADAD] rounded-full -start-3 ring-4 ring-white"></span>
                                        <div className="ml-6">
                                          <h3 className="mb-1 text-base font-semibold text-gray-900">{log.title}</h3>
                                          <time className="block mb-2 text-sm font-normal leading-none text-gray-400">
                                            {moment(log.timestamp).format('DD/MM/YYYY HH:mm:ss')}
                                          </time>
                                        </div>
                                      </li>
                                    ))}
                                  </ol>
                                ) : (
                                  <ol className="relative border-s ">
                                    <li className="mb-10 ms-6">
                                    <span className="absolute flex items-center justify-center w-5 h-5 bg-[#02ADAD] rounded-full -start-3 ring-4 ring-white"></span>
                                      <h3 className="flex items-center mb-1 text-base font-semibold text-gray-900">No Activity Log Data</h3>
                                      <time className="block mb-2 text-sm font-normal leading-none text-gray-400">{moment().format('DD/MM/YYYY HH:mm:ss')}</time>
                                    </li>
                                  </ol>
                                )}
                              </div>
                            )}
                          </div>
                          {requestItems && requestItems?.filename && (
                            <div className='flex items-center justify-between border border-teal-400 bg-teal-50 rounded-md px-4 py-2'>
                              <div className='flex items-center'>
                                <FontAwesomeIcon icon={faFile} className="h-4 w-4 text-[#02ADAD] pr-2" />
                                <div className='text-gray-800'>PPQ Results Document</div>
                              </div>
                              <div className='flex items-center space-x-2'>
                                <FontAwesomeIcon
                                  icon={faDownload}
                                  className="text-[#02ADAD] hover:text-teal-700 cursor-pointer"
                                  onClick={handleDownload}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default ViewRequest;

