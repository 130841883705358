import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { Fragment } from 'react'
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark, faPlus, faEdit } from '@fortawesome/free-solid-svg-icons'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import useAuth from '../hooks/useAuth'

const SlideOut = ({ open, setOpen, auth, requestData, orgInfo }) => {
  const { setShowLoader, createInfo } = useAuth()
  const [value, setValue] = useState({})
  const axios = useAxiosPrivate()
  const [error, setError] = useState('')
  const maxDate = moment().add(4, 'weeks').format('YYYY-MM-DD')
  const [editMode, setEditMode] = useState(false)
  const [schoolName, setSchoolName] = useState(orgInfo.name)
  const [schoolPostcode, setSchoolPostcode] = useState(orgInfo.postcode)
  const [termsAccepted, setTermsAccepted] = useState(false)
  const [showTerms, setShowTerms] = useState(true)

  useEffect(() => {
    setSchoolName(orgInfo.name)
    setSchoolPostcode(orgInfo.postcode)
  }, [orgInfo])

  const toggleEditMode = () => {
    setEditMode(!editMode)
  }

  const handleDateChange = (e) => {
    const selectedDate = e.target.value
    const currentDate = moment()
    const maxDateFormatted = moment(maxDate).format('DD/MM/YYYY')

    if (moment(selectedDate).isAfter(maxDate)) {
      setError(`The date cannot be after ${maxDateFormatted}`)
      setValue({ ...value, startDate: '' })
    } else if (moment(selectedDate).isSameOrBefore(currentDate, 'day')) {
      setError('The date cannot be today or in the past')
      setValue({ ...value, startDate: '' })
    } else {
      setError('')
      setValue({ ...value, startDate: selectedDate })
    }
  }

  const handleCheckboxChange = () => {
    let updated = !termsAccepted
    setTermsAccepted(updated)
  }

  useEffect(() => {
    if (requestData) {
      const requestInfo = JSON.parse(requestData.request_information)
      setValue({
        staffName: requestInfo.staff_details.staff_name,
        staffEmail: requestInfo.staff_details.staff_email,
        startDate: requestInfo.staff_details.start_date,
        roleAppliedFor: requestInfo.staff_details.role_applied_for,
        description: requestData.description,
      })
    } else {
      setValue({
        staffName: '',
        staffEmail: '',
        startDate: '',
        roleAppliedFor: '',
        description: '',
      })
    }
    if (
      auth?.currentPolicy.product === 'SWB OH+' ||
      auth?.currentPolicy.product === 'MAT Policy' ||
      auth?.currentPolicy.product === 'MAT Policy Plus'
    ) {
      setShowTerms(false)
      setTermsAccepted(true)
    }
  }, [requestData])

  const submitRequest = async (e) => {
    e.preventDefault()
    const form = e.currentTarget
    if (!form.checkValidity()) {
      return
    }
    setShowLoader(true)
    const requestData = {
      titleId: '13172b95-d9db-431d-957c-7f3049c24f6f',
      staffName: value.staffName,
      staffEmail: value.staffEmail,
      startDate: value.startDate,
      roleAppliedFor: value.roleAppliedFor,
      description: value.description,
      policyId: auth?.currentPolicy?.policy_id,
      schoolName: schoolName,
      schoolPostcode: schoolPostcode,
      orgId: auth.schools[0].id,
    }

    try {
      await axios
        .post('/service-requests/create', requestData)
        .then((response) => {
          if (response.status === 200) {
            clearFields()
            setOpen(false)
            createInfo('success', 'PPQ Request created successfully')
            setTimeout(() => {
              window.location.reload()
            }, 500)
          } else {
            console.error('Failed to submit request')
          }
        })
        .catch((e) => {
          createInfo('error', 'Failed to submit request, please try again')
        })
        .finally(() => {
          setShowLoader(false)
        })
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const clearFields = () => {
    setValue({})
    setTermsAccepted(false)
  }

  const handleClose = () => {
    setOpen(false)
    setTermsAccepted(false)
    setValue({})
  }

  const isValidUKPostcode = (postcode) => {
    const postcodeRegex = /^([A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2})$/i
    return postcodeRegex.test(postcode)
  }

  const isFormValid = () => {
    if (
      value?.staffName?.length > 0 &&
      value?.staffEmail?.length > 0 &&
      value?.startDate?.length > 0 &&
      value?.roleAppliedFor?.length > 0 &&
      schoolName.length > 0 &&
      schoolPostcode.length > 0
    ) {
      return true
    } else {
      return false
    }
  }

  return (
    <Dialog open={open} onClose={handleClose} className="relative z-[100]">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition duration-500 ease-in-out data-[closed]:opacity-0"
      />
      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <DialogPanel
              transition
              className="pointer-events-auto w-screen max-w-2xl transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
            >
              <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                <div className="bg-[#02ADAD] px-4 py-6 sm:px-6">
                  <div className="flex items-center justify-between">
                    <div>
                      <DialogTitle className="text-lg font-semibold leading-6 text-white">
                        {requestData
                          ? `Viewing ${value.staffName}`
                          : 'Create PPQ Request'}
                      </DialogTitle>
                      <div className="text-xs text-white">
                        Please complete the required information below to submit
                        your request.
                      </div>
                    </div>
                    <div className="ml-3 flex h-7 items-center">
                      <button
                        type="button"
                        className="relative rounded-md bg-[#02ADAD] text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                        onClick={() => {
                          clearFields()
                          setOpen(false)
                        }}
                      >
                        <span className="absolute -inset-2.5" />
                        <span className="sr-only">Close panel</span>
                        <FontAwesomeIcon icon={faXmark} />
                      </button>
                    </div>
                  </div>
                </div>
                <form onSubmit={submitRequest}>
                  <div className="relative mt-6 flex-1 px-4 sm:px-6">
                    <div className="px-4 py-2">
                      <div className="text-[#02ADAD] text-lg font-medium">
                        Prospective Staff Member details
                      </div>
                      <div className="flex justify-between space-x-6">
                        <div className="mt-4 w-full">
                          <label htmlFor="">
                            First Name <span className="text-red-500">*</span>
                          </label>
                          <input
                            type="text"
                            required
                            className="border rounded-md py-2 px-4 w-full"
                            onChange={(e) =>
                              setValue((prevValue) => ({
                                ...prevValue,
                                firstName: e.target.value,
                                staffName: `${e.target.value} ${prevValue.lastName}`,
                              }))
                            }
                            defaultValue={value.firstName}
                          />
                        </div>
                        <div className="mt-4 w-full">
                          <label htmlFor="">
                            Last Name <span className="text-red-500">*</span>
                          </label>
                          <input
                            type="text"
                            required
                            className="border rounded-md py-2 px-4 w-full"
                            onChange={(e) =>
                              setValue((prevValue) => ({
                                ...prevValue,
                                lastName: e.target.value,
                                staffName: `${prevValue.firstName} ${e.target.value}`,
                              }))
                            }
                            defaultValue={value.lastName}
                          />
                        </div>
                      </div>
                      <div className="flex flex-col mt-4">
                        <label htmlFor="">
                          Start Date (Must be within 4 weeks){' '}
                          <span className="text-red-500">*</span>
                        </label>
                        <input
                          onChange={handleDateChange}
                          value={value.startDate || ''}
                          type="date"
                          required
                          className="border rounded-md py-2 px-4 cursor-pointer"
                          min={moment().add(1, 'days').format('YYYY-MM-DD')}
                          max={maxDate}
                          onClick={(e) => e.target.showPicker()}
                          style={{ width: '100%' }}
                        />
                        {error && <p className="text-red-500 mt-2">{error}</p>}
                      </div>
                      <div className="flex flex-col mt-4">
                        <label htmlFor="">
                          Email Address <span className="text-red-500">*</span>
                        </label>
                        <input
                          type="email"
                          className="border rounded-md py-2 px-4"
                          required
                          onChange={(e) =>
                            setValue({ ...value, staffEmail: e.target.value })
                          }
                          defaultValue={value.staffEmail}
                        />
                      </div>
                      <div className="flex flex-col mt-4">
                        <label htmlFor="">
                          Role Applied For{' '}
                          <span className="text-red-500">*</span>
                        </label>
                        <input
                          type="text"
                          required
                          className="border rounded-md py-2 px-4"
                          onChange={(e) =>
                            setValue({
                              ...value,
                              roleAppliedFor: e.target.value,
                            })
                          }
                          defaultValue={value.roleAppliedFor}
                        />
                      </div>
                      <div className="flex flex-col mt-4">
                        <label htmlFor="">Additional Info</label>
                        <textarea
                          type="text"
                          className="border rounded-md py-2 px-4"
                          onChange={(e) =>
                            setValue({ ...value, description: e.target.value })
                          }
                          defaultValue={value.description}
                        ></textarea>
                      </div>
                      <div className="text-xs mt-2">
                        <span className="text-red-500">*</span> Denotes required
                        fields.
                      </div>
                    </div>
                    <div className="px-4 mt-4">
                      <div className="flex items-center justify-between">
                        <div>
                          <DialogTitle className="text-lg font-semibold leading-6 text-[#02ADAD]">
                            Your Details
                          </DialogTitle>
                          <div className="text-xs text-[#02ADAD]">
                            These will be shown on the questionnaire
                          </div>
                        </div>
                        {orgInfo.type === 'trust' && (
                          <div>
                            <FontAwesomeIcon
                              icon={faEdit}
                              className="text-[#02ADAD] hover:text-cyan-700 cursor-pointer"
                              onClick={toggleEditMode}
                            />
                          </div>
                        )}
                      </div>

                      <div className="mt-4 flex">
                        <div className="text-gray-600 mr-2">Name:</div>
                        <div className="font-bold">
                          {auth.user.contact_name ?? ''}
                        </div>
                      </div>

                      <div className="mt-2 flex">
                        <div className="text-gray-600 mr-2">Email:</div>
                        <div className="font-bold">
                          {auth.user.contact_email ?? ''}
                        </div>
                      </div>

                      <div className="mt-2 flex items-center">
                        <div className="text-gray-600 mr-2">School Name:</div>
                        {editMode ? (
                          <input
                            type="text"
                            defaultValue={schoolName}
                            onChange={(e) => setSchoolName(e.target.value)}
                            className="font-bold border rounded-md border-gray-300 px-2 py-1 w-3/4"
                          />
                        ) : (
                          <div className="font-bold">{schoolName}</div>
                        )}
                      </div>

                      <div className="mt-2 flex items-center">
                        <div className="text-gray-600 mr-2">
                          School Postcode:
                        </div>
                        {editMode ? (
                          <input
                            type="text"
                            defaultValue={schoolPostcode}
                            onChange={(e) => setSchoolPostcode(e.target.value)}
                            className="font-bold border rounded-md border-gray-300 px-2 py-1"
                          />
                        ) : (
                          <div className="font-bold">{schoolPostcode}</div>
                        )}
                      </div>
                      {showTerms && (
                        <div className="mt-8 flex items-center">
                          <label>
                            <input
                              type="checkbox"
                              checked={termsAccepted}
                              onChange={handleCheckboxChange}
                            />
                            <span style={{ marginLeft: '8px' }}>
                              If this employee requires a follow-up call from an
                              Occupational Health Advisor, this will be
                              chargeable at £45. Please tick to agree to these
                              terms and proceed.
                            </span>
                          </label>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className=" hover:shadow-md w-11/12 mx-auto my-4">
                    <button
                      type="submit"
                      className={`border flex items-center rounded h-[45px] w-full ${
                        isFormValid() && termsAccepted
                          ? 'border-[#02ADAD]'
                          : 'border-gray-400 cursor-not-allowed'
                      }`}
                      onClick={submitRequest}
                      disabled={!(isFormValid() && termsAccepted)}
                    >
                      <div className="text-[#02ADAD] h-full flex justify-center items-center px-10 font-medium w-full">
                        {requestData ? 'Update Request' : 'Submit Request'}
                      </div>
                      <div className="bg-[#02ADAD] text-white h-full flex w-[45px] justify-center items-center">
                        <FontAwesomeIcon icon={requestData ? faEdit : faPlus} />
                      </div>
                    </button>
                  </div>
                </form>
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default SlideOut
