/* eslint-disable */
import React, { useRef, useState, useEffect } from 'react'
import {
  faCircleExclamation,
  faCheck,
  faTimes,
  faArrowRight,
  faChevronRight,
  faEnvelope,
  faPhone,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTheme } from '../context/ThemeProvider'

import {
  redirect,
  useNavigate,
  useSearchParams,
  useParams,
} from 'react-router-dom'
import axios from '../api/axios'

const EMAIL_REGEX =
  /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,12})$/
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/
const CREATE_URL = 'v2/staff-hub/create-password'
const SCHOOLS_URL = 'v2/staff-hub/get-schools-for-contact'
const GET_EMAIL = 'v2/staff-hub/get-email-from-token'

const CreatePassword = () => {
  let [searchParams] = useSearchParams()
  const theme = useTheme()
  const style = {
    backgroundColor: theme.backgroundColor,
    color: theme.textColor,
  }
  const { token } = useParams()
  const navigate = useNavigate()

  const passwordRef = useRef()
  const errRef = useRef()

  const [email, setEmail] = useState('')
  const [validEmail, setValidEmail] = useState(false)
  const [emailFocus, setEmailFocus] = useState(false)

  const [pwd, setPwd] = useState('')
  const [pwdStarted, setPwdStarted] = useState(false)
  const [validPwd, setValidPwd] = useState(false)
  const [pwdFocus, setPwdFocus] = useState(false)

  const [schools, setSchools] = useState([])
  const [selectedSchools, setSelectedSchools] = useState([])
  const [validSchools, setValidSchools] = useState(false)

  const [matchPwd, setMatchPwd] = useState('')
  const [matchPwdStarted, setMatchPwdStarted] = useState(false)
  const [validMatch, setValidMatch] = useState(false)
  const [matchFocus, setMatchFocus] = useState(false)

  const [errMsg, setErrMsg] = useState('')

  useEffect(() => {
    axios
      .get(GET_EMAIL, {
        params: {
          token: token,
          type: '',
        },
      })
      .then(function (response) {
        if (response.data.error) {
          navigate('/', {
            replace: true,
            state: {
              messageType: 'error',
              message: 'This create password link is invalid',
            },
          })
        }

        let repEmail = response.data.result.email

        setEmail(repEmail)

        axios
          .get(SCHOOLS_URL, {
            params: {
              contact_email: repEmail,
            },
          })
          .then((response) => {
            if (response.data.result.length == 0) {
              navigate('/login', {
                state: {
                  messageType: 'error',
                  message: 'You do not have permission to access this page',
                },
              })
            }
            setSchools(response.data.result)
          })

        const result = EMAIL_REGEX.test(repEmail)
        setValidEmail(result)
        setErrMsg('')
      })
  }, [token])

  useEffect(() => {
    const result = PWD_REGEX.test(pwd)
    setValidPwd(result)
    const match = pwd === matchPwd
    setValidMatch(match)
  }, [pwd, matchPwd])

  useEffect(() => {
    setErrMsg('')
  }, [pwd, matchPwd])

  const toggleSelection = (event) => {
    const { value, checked } = event.target
    setSelectedSchools((prevSelectedItems) => {
      if (checked) {
        return [...prevSelectedItems, value]
      } else {
        return prevSelectedItems.filter((item) => item !== value)
      }
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    const v1 = PWD_REGEX.test(pwd)
    if (!v1) {
      setErrMsg('Invalid Entry')
      return
    }

    try {
      const response = await axios.post(CREATE_URL, {
        contact_reset: token,
        contact_email: email,
        contact_password: pwd,
        contact_schools: selectedSchools,
        password_confirmation: matchPwd,
      })
      navigate('/', { replace: true })
    } catch (error) {
      if (!error?.response) {
        setErrMsg('No Server Response')
      } else if (error.response?.status === 422) {
        setErrMsg('The email provided already exists.')
      } else {
        setErrMsg('Error Occured. Could not Reset Password.')
      }
    }
  }

  return (
    <>
      <h3 className="text-2xl sm:text-3xl md:text-5xl font-bold text-white mb-8 sm:mb-16">
        Create Passsword
      </h3>
      <div className="w-full max-w-4xl flex justify-center flex-col-reverse lg:flex-row">
        <div className="w-full lg:w-7/12 bg-white shadow-lg rounded-md overflow-hidden">
          <div className="w-full p-6 lg:p-12">
            <form
              onSubmit={handleSubmit}
              method="POST"
              className="space-y-4 sm:space-y-6"
            >
              <div className="relative">
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-slate-500"
                  >
                    Email address
                  </label>
                  <div>
                    <input
                      type="email"
                      id="email"
                      value={email}
                      required
                      readOnly
                      aria-describedby="emailnote"
                      placeholder="Email"
                      disabled
                      className="text-base appearance-none border rounded w-full py-2 px-3 text-gray-700 "
                    />
                  </div>
                </div>
              </div>

              <div className="relative">
                <div>
                  <label
                    htmlFor="pwd"
                    className="block text-sm font-medium text-slate-500"
                  >
                    Password
                  </label>
                  <div>
                    <input
                      type="password"
                      id="pwd"
                      ref={passwordRef}
                      onChange={(e) => {
                        setPwd(e.target.value)
                        setPwdStarted(true)
                      }}
                      required
                      aria-invalid={validPwd ? 'false' : 'true'}
                      aria-describedby="pwdnote"
                      placeholder="Password"
                      onFocus={() => setPwdFocus(true)}
                      onBlur={() => setPwdFocus(false)}
                      className={`text-base appearance-none border rounded w-full py-2 px-3 text-gray-700 ${
                        pwd.length > 0 || pwdStarted
                          ? validPwd
                            ? ''
                            : 'border-red-500'
                          : ''
                      } `}
                    />
                    {pwd.length > 0 || pwdStarted ? (
                      validPwd ? (
                        ''
                      ) : (
                        <span className="text-sm text-red-500">
                          Password does not meet criteria
                        </span>
                      )
                    ) : (
                      ''
                    )}
                  </div>
                </div>

                <span
                  className={
                    validPwd && pwd
                      ? 'valid input-check !right-[0.5rem] !top-[30px]'
                      : 'hide input-check !right-[0.5rem] !top-[30px]'
                  }
                >
                  <FontAwesomeIcon icon={faCheck} />
                </span>
                <span
                  className={
                    validPwd && pwd
                      ? 'hide input-check !right-[0.5rem] !top-[30px]'
                      : 'invalid input-check !right-[0.5rem] !top-[30px]'
                  }
                >
                  <FontAwesomeIcon icon={faTimes} />
                </span>
                <p
                  id="pwdnote"
                  className={
                    pwdFocus && pwd && !validPwd ? 'instructions' : 'offscreen'
                  }
                >
                  <FontAwesomeIcon icon={faCircleExclamation} />
                  8 to 24 characters. <br />
                  Must include uppercase and lowercase letters, a number and a
                  special character. <br />
                  Allowed special characters:
                  <span aria-label="exclamation mark"> !</span>
                  <span aria-label="at symbol"> @</span>
                  <span aria-label="hashtag"> #</span>
                  <span aria-label="dollar sign"> $</span>
                  <span aria-label="percent"> %</span>
                </p>
              </div>

              <div className="relative">
                <div>
                  <label
                    htmlFor="confirm_pwd"
                    className="block text-sm font-medium text-slate-500"
                  >
                    Confirm Password
                  </label>
                  <div>
                    <input
                      type="password"
                      id="confirm_pwd"
                      onChange={(e) => {
                        setMatchPwd(e.target.value)
                        setMatchPwdStarted(true)
                      }}
                      required
                      aria-invalid={validMatch ? 'false' : 'true'}
                      aria-describedby="confirmnote"
                      placeholder="Confirm Password"
                      onFocus={() => setMatchFocus(true)}
                      onBlur={() => setMatchFocus(false)}
                      className={`text-base appearance-none border rounded w-full py-2 px-3 text-gray-700 ${
                        matchPwd.length > 0 || matchPwdStarted
                          ? validPwd
                            ? ''
                            : 'border-red-500'
                          : ''
                      } `}
                    />
                    {matchPwd.length > 0 || matchPwdStarted ? (
                      validMatch ? (
                        ''
                      ) : (
                        <span className="text-sm text-red-500">
                          Password does not match the first password input.
                        </span>
                      )
                    ) : (
                      ''
                    )}
                  </div>
                </div>

                <span
                  className={
                    validMatch && matchPwd
                      ? 'valid input-check !right-[0.5rem] !top-[30px]'
                      : 'hide input-check !right-[0.5rem] !top-[30px]'
                  }
                >
                  <FontAwesomeIcon icon={faCheck} />
                </span>
                <span
                  className={
                    validMatch && matchPwd
                      ? 'hide input-check !right-[0.5rem] !top-[30px]'
                      : 'invalid input-check !right-[0.5rem] !top-[30px]'
                  }
                >
                  <FontAwesomeIcon icon={faTimes} />
                </span>
                <p
                  id="confirmnote"
                  className={
                    matchFocus && matchPwd && !validMatch
                      ? 'instructions'
                      : 'offscreen'
                  }
                >
                  <FontAwesomeIcon icon={faCircleExclamation} />
                  Must match the first password input.
                </p>
              </div>

              <div className="relative">
                <div>
                  <label
                    htmlFor="schoolselect"
                    className="block text-sm font-medium text-slate-500 mb-2"
                  >
                    Select Schools
                    {selectedSchools.length > 0 ? (
                      <span className="valid ml-2 input-check !right-[0.5rem] !top-[30px]">
                        <FontAwesomeIcon icon={faCheck} />
                      </span>
                    ) : (
                      <span className="invalid ml-2 input-check !right-[0.5rem] !top-[30px]">
                        <FontAwesomeIcon icon={faTimes} />
                      </span>
                    )}
                  </label>
                  <p className="mb-4 text-sm">
                    Please select the schools that you wish to set the password
                    for.
                  </p>
                  <div>
                    {schools?.map((item) => {
                      return (
                        <div
                          className="flex text-sm align-center mr-2"
                          key={item.id}
                        >
                          <input
                            type="checkbox"
                            value={item.id}
                            onChange={toggleSelection}
                            className="form-check-label font-bold ml-4 mr-2"
                          />
                          <label className="pt-[2px]">{item.name}</label>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  disabled={!validPwd || !validMatch}
                  className="button"
                  style={{
                    borderColor:
                      !validPwd || !validMatch ? 'gray' : style.backgroundColor,
                    cursor:
                      !validPwd || !validMatch ? 'not-allowed' : 'pointer',
                  }}
                >
                  <span
                    style={{
                      color: !validPwd || !validMatch ? 'gray' : style.color,
                    }}
                  >
                    Create Password
                  </span>
                  <span
                    style={{
                      backgroundColor:
                        !validPwd || !validMatch
                          ? 'gray'
                          : style.backgroundColor,
                    }}
                  >
                    <FontAwesomeIcon icon={faArrowRight} />
                  </span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default CreatePassword
