import React from 'react'

function AccountSettings() {
  return (
    <div className="mb-8 sm:mb-20">
      <h3 className="text-2xl sm:text-3xl md:text-5xl font-bold text-white text-center">
        Account Settings
      </h3>
      <h4 className="text-lg font-medium text-white text-center">
        Here you can manage your account details
      </h4>
    </div>
  )
}

export default AccountSettings