import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheck, faChevronRight, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'
import { useTheme } from '../context/ThemeProvider'
function Contact() {
  const theme = useTheme();
 
  const style = {
    backgroundColor: theme.backgroundColor,
    color: theme.textColor,
  };
 
  return (
    <div className="w-full bg-white shadow-lg rounded-md overflow-hidden max-w-4xl flex mt-8 lg:mt-16 mx-auto">
      <div className="flex min-h-full flex-1">
        <div className="w-full p-6 lg:p-12">
          <p className="text-slate-700 font-bold text-lg sm:text-xl lg:text-2xl leading-6 mb-6 text-center sm:text-left">
            Have a Question? Get in touch with us
          </p>
 
          <div className="flex flex-col sm:flex-row w-full">
            <div className="inline-block">
              <a href={theme.phone_number.link} className="button" style={{ borderColor: style.color}} >
                <span className="" style={{color: style.color}}>Call us</span>
                <span className="" style={{backgroundColor: style.backgroundColor}}>
                  <FontAwesomeIcon icon={faPhone} />
                </span>
              </a>
            </div>
            <div className="inline-block mt-6 sm:mt-0 sm:ml-8">
              <a href={theme.email} className="button" style={{ borderColor: style.color}}>
                <span  className="" style={{color: style.color}}>Email us</span>
                <span  className="" style={{backgroundColor: style.backgroundColor}}>
                  <FontAwesomeIcon icon={faEnvelope} />
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
 
export default Contact
 