import { Outlet, Navigate, NavLink, Link } from 'react-router-dom'
import React, { useRef, useState } from 'react'
import Footer from '../components/Footer'
import useAuth from '../hooks/useAuth'
import useLogout from '../hooks/useLogout'
// import Header from '../components/Header'
// import LeftBar from '../components/LeftBar'
import { useTheme } from '../context/ThemeProvider'
import Loader from '../components/Loader'

function AuthLayout() {
  const { auth, showLoader } = useAuth()
  const [active, setActive] = useState('dashboard')
  const theme = useTheme()
  const style = {
    backgroundColor: theme.backgroundColor,
    color: theme.textColor,
    logo: theme.logo,
    footer: theme.footer,
  }
  const {hostname} = window.location

  const navClasses =
    'py-1 px-3 rounded-md items-center hover:bg-[#2babe0] hover:text-white text-center'
  const logout = useLogout()

  const guideLink = () => 
    {
      let guide = 'https://kisdigital.s3.eu-west-2.amazonaws.com/hub/SAS+-+Portal+Guide.pdf';
      if (window.location.href.includes('schoosmutuals'))
        {
          guide = 'https://kisdigital.s3.eu-west-2.amazonaws.com/hub/Schools+Mutual+-+Portal+Guide.pdf';
        }
        return guide;
      }


 
   
  return (
    <div className="bg-slate-50/50 w-full">
      {showLoader && <Loader />}
      <div className="absolute w-full">
        {auth.token ? (
          <div className="h-[50px] sm:h-[60px] bg-white shadow z-[100] relative flex justify-between items-center px-4 md:px-8">
            <h2
              className="text-base sm:text-xl font-bold "
              style={{ color: style.color }}
            >
              {hostname.includes('mutual') ? (
                  <NavLink to={`/dashboard`}>Schools Mutual</NavLink>
                ) : (
                  <NavLink to={`/dashboard`}>Schools Advisory Service</NavLink>
                )
              }
            </h2>
            {/* if logged in */}
            <div className="flex space-x-4 items-center">
              <NavLink
                to="/dashboard"
                className={({ isActive }) =>
                  isActive
                    ? 'bg-[#2babe0] text-white ' +
                      navClasses +
                      ' pointer-events-none'
                    : navClasses + ''
                }
              >
                Dashboard
              </NavLink>
              {auth?.schools?.length > 1 && (
                <NavLink
                  to="account-select"
                  className={({ isActive }) =>
                    isActive
                      ? 'bg-[#2babe0] ' +
                        navClasses +
                        ' pointer-events-none text-white'
                      : navClasses + ' '
                  }
                >
                  <span className="w-[300px]">Switch School</span>
                </NavLink>
              )}
              <a
                href={guideLink()} 
                target="_blank"
                rel="noopener noreferrer"
                className={navClasses + ' '}>
                <span className="w-[300px]">View Guide</span>
              </a>
              {/* <NavLink to={`/users/${auth.user.contact_id}/account-settings`} className={({ isActive }) =>
                isActive
                  ? 'bg-[#2babe0] ' + navClasses + ' pointer-events-none text-white'
                  : navClasses + ' '
              }>
                  <span className='w-[300px]'>Account Settings</span>
            </NavLink> */}
              <p
                className="text-slate-800 text-sm sm:text-base cursor-pointer"
                onClick={logout}
              >
                Logout
              </p>
            </div>
          </div>
        ) : null}
        <div
          className="h-[150px] sm:h-[80px] z-[9] relative w-full"
          style={{ backgroundColor: style.backgroundColor }}
        ></div>
        <div
          className="w-full h-[283px] sm:block hidden"
          style={{ backgroundImage: theme.backgroundImage }}
        >
          &nbsp;
        </div>
      </div>

      {/* <Header /> */}
      <div className="relative pt-[90px] sm:pt-28">
        {/* Left */}
        <div className="flex items-center flex-col z-10 relative w-full px-4 md:px-8 min-h-[650px]">
          <Outlet />
        </div>
        <Footer theme={theme} />

        {/* </main> */}
      </div>
    </div>
  )
}

export default AuthLayout
