// ThemeContext.js
import React, { createContext, useContext, useState, useEffect } from 'react'
import { themes } from '../themes'

const ThemeContext = createContext()

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(themes.default)

  useEffect(() => {
    const { hostname } = window.location
    let link = document.querySelector("link[rel~='icon']")
    if (!link) {
      link = document.createElement('link')
      link.rel = 'icon'
      document.getElementsByTagName('head')[0].appendChild(link)
    }
    let selectedTheme = themes.default
    if (hostname.includes('localhost')) {
      selectedTheme = themes.default
      document.title = 'Schools Advisory Service - School Portal'
      link.href = '/sas_favicon.ico'
    } else if (hostname.endsWith('.schoolsmutual.co.uk')) {
      document.title = 'Schools Mutual - School Portal'
      selectedTheme = themes.schoolsMutual // Wildcard domain check
      link.href = '/mutual_favicon.ico'
    }

    setTheme(selectedTheme)
  }, [])

  return <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>
}

export const useTheme = () => useContext(ThemeContext)
