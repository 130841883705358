// import { faTimes } from "@fortawesome/free-solid-svg-icons"
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useTheme } from "../context/ThemeProvider"

function Modal({ title, body, show }) {
  const theme = useTheme()

  return (
    <div
      className="fixed top-0 left-0 w-screen h-screen flex justify-center items-center"
      style={{ zIndex: 999 }}
    >
      <div className="bg-slate-700/50 w-full h-full">&nbsp;</div>
      <div className="flex flex-col absolute p-6 bg-white w-[550px] rounded-xl shadow-md">
        {/* <span className="absolute right-1 top-1 flex justify-center items-center bg-red-300 rounded-full text-red-800 h-5 w-5 text-sm hover:bg-red-400 cursor-pointer" onClick={() => show(false)}>
            <FontAwesomeIcon icon={faTimes} className=''/>
          </span> */}
        <h2 className="text-center text-xl font-bold border-b border-b-slate-300 pb-2" style={{ color: theme.textColor }}>
          {title}
        </h2>
        <div className="mt-4">{body}</div>
      </div>
    </div>
  )
}

export default Modal
