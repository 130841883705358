import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ThemeProvider } from './context/ThemeProvider'
import { AuthProvider } from './context/AuthProvider'

import { BrowserRouter, Routes, Route } from 'react-router-dom'

import { Provider, ErrorBoundary } from '@rollbar/react'
const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
  environment: process.env.REACT_APP_ENV,
  captureUncaught: true,
  captureUnhandledRejections: true,
}

// const RollbarGroup = () => {
//   return (
//     <Provider config={rollbarConfig}>
//       <ErrorBoundary>
//         <AuthProvider>
//             <Routes>
//               <Route path="/*" element={<App />} />
//             </Routes>
//           </AuthProvider>
//       </ErrorBoundary>
//     </Provider>
//   )
// }

const LocalGroup = () => {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/*" element={<App />} />
      </Routes>
    </AuthProvider>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <BrowserRouter>
        <LocalGroup />
    </BrowserRouter>
  </React.StrictMode>
)

reportWebVitals()
