import { Outlet, Navigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import useAuth from "../hooks/useAuth";
import MainLoader from "./MainLoader";

const CheckLogin = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const { auth } = useAuth();
  const location = useLocation();

  useEffect(() => {
    // Only run this effect if auth.token changes
    if (auth.token) {
      // Check if user should be redirected
      if (location.pathname === "/") {
        setShouldRedirect(true);
      } else {
        setShouldRedirect(false);
      }
    } else {
      setShouldRedirect(false);
    }

    // Set loading to false after checks
    setIsLoading(false);
  }, [auth.token, location.pathname]); // Add auth.token and location.pathname as dependencies

  // Avoid the redirect loop by checking isLoading and shouldRedirect
  if (isLoading) {
    return <MainLoader />;
  }

  if (shouldRedirect) {
    // Ensure that it redirects only if it needs to
    return <Navigate to="/dashboard" state={{ from: location }} replace />;
  }

  return <Outlet />;
};

export default CheckLogin;