/* eslint-disable */
import React, { useRef, useState, useEffect } from 'react'
import { faCircleExclamation, faArrowRight, faCheck, faTimes, faChevronRight, faEnvelope, faPhone, faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTheme } from '../context/ThemeProvider'

import { Link, useNavigate } from 'react-router-dom'

import axios from '../api/axios'

const EMAIL_REGEX = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,12})$/
const FORGOT_PWD_URL = 'v2/staff-hub/reset-password-request'

const ForgotPassword = () => {

  const emailRef = useRef()
  const errRef = useRef()
  const theme = useTheme();
  const style = {
    backgroundColor: theme.backgroundColor,
    color: theme.textColor,
  };
  const { hostname } = window.location;

  const [email, setEmail] = useState('')
  const [validEmail, setValidEmail] = useState(false)
  const [emailFocus, setEmailFocus] = useState(false)

  const [errMsg, setErrMsg] = useState('')
  const [success, setSuccess] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    if(!success) {
      emailRef.current.focus()
    }
  }, [])

  useEffect(() => {
    const result = EMAIL_REGEX.test(email)
    setValidEmail(result)
  }, [email])

  useEffect(() => {
    setErrMsg('')
  }, [email,])

  const handleSubmit = async (e) => {
    e.preventDefault()

    const v1 = EMAIL_REGEX.test(email)
    if (!v1) {
      setErrMsg('Invalid Entry')
      return
    }

    try {
      await axios.post(FORGOT_PWD_URL + '/' + hostname, { email })
      setEmail('')
      setSuccess(true);

    } catch (error) {
      if (!error?.response) {
        setErrMsg('No Server Response')
      } else if (error.response?.status === 422) {
        setErrMsg('The email provided already exists.')
      } else {
        setErrMsg('Error Occured. Could not create account.')
      }
    }
  }

  return (
    <>
      <h3 className="text-2xl sm:text-3xl md:text-5xl font-bold text-white mb-8 sm:mb-16">
        Forgot your Password?
      </h3>
      
      {errMsg && (
        <div className="rounded-md bg-red-50 p-4 mb-4">
          <div className="flex">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" aria-hidden="true" className="h-5 w-5" fill="#dc2626">
              <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z"/>
            </svg>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800" ref={errRef}>{errMsg}</h3>
            </div>
          </div>
        </div>
      )}

      <div className="w-full max-w-4xl flex justify-center flex-col-reverse lg:flex-row">
        <div className="w-full lg:w-7/12 bg-white shadow-lg rounded-md overflow-hidden">

          {success ? (
            <div className='text-center w-full p-6 lg:p-12'>
              <div>  
                <FontAwesomeIcon icon={faCircleCheck} className='h-14 w-14 text-green-500' />
              </div>

              <h3 className='text-lg font-bold leading-6 text-gray-700 mt-4 mb-4'>Sent Successfully</h3>

              <div className='pb-6'>
                <p>If this email is in use, we will send a reset password email. please check your inbox.</p>
              </div>

              <button onClick={() => {navigate('/login')}} className="button mt-4" style={{borderColor: style.backgroundColor}}>
                <span className="" style={{ color: style.color}}>Continue</span>
                <span className="" style={{backgroundColor: style.backgroundColor}}>
                  <FontAwesomeIcon icon={faArrowRight} />
                </span>
              </button>
            </div>
          ) : (
            <div className="w-full p-6 lg:p-12">
              <form onSubmit={handleSubmit} method="POST" className="space-y-4 sm:space-y-6">
                <div className='relative'>
                  <div>
                    <label htmlFor="email" className="block text-sm font-medium text-slate-500">
                      Email address
                    </label>
                    <div className="">
                      <input type='email'
                      id='email'
                      ref={emailRef}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      aria-invalid={validEmail ? 'false' : 'true'}
                      aria-describedby='emailnote'
                      placeholder='Email'
                      autofill='false'
                      onFocus={() => setEmailFocus(true)}
                      onBlur={() => setEmailFocus(false)}
                      className="text-base appearance-none border rounded w-full py-2 px-3 text-gray-700 "
                      />
                    </div>
                  </div>

                  <span className={validEmail ? 'valid input-check !right-[0.5rem] !top-[30px]' : 'hide input-check !right-[0.5rem] !top-[30px]'}>
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  <span className={validEmail || !email ? 'hide input-check !right-[0.5rem] !top-[30px]' : 'invalid input-check !right-[0.5rem] !top-[30px]'}>
                    <FontAwesomeIcon icon={faTimes} />
                  </span>
                  <p id='emailnote' className={emailFocus && email && !validEmail ? 'instructions' : 'offscreen'}>
                    <FontAwesomeIcon icon={faCircleExclamation} />
                    Invalid email address.
                    <br /> Valid email addresses can contain only letters, numbers, '@' and '.'
                  </p>
                </div>

                <div>
                  <button type="submit" className="button" style={{borderColor: !email || !validEmail ? 'gray' : style.backgroundColor, cursor: !email || !validEmail ? 'not-allowed' : 'pointer'}}>
                    <span className="" style={{ color: !email || !validEmail ? 'gray' : style.color}}>Reset Password</span>
                    <span className="" style={{backgroundColor: !email || !validEmail ? 'gray' :  style.backgroundColor}}>
                      <FontAwesomeIcon icon={faArrowRight} />
                    </span>
                  </button>
                </div>

                <div className="text-sm flex">
                  <a href="/login" className="mt-1.5 text-xs" style={{color: style.color}}>
                    Back to Login
                  </a>
                </div>
              </form>
            </div>
          )}
          
        </div>
      </div>
    </>
  )
    
}

export default ForgotPassword
