import useAxiosPrivate from "../hooks/useAxiosPrivate"
import useAuth from "./useAuth"

const useLogout = () => {
  const { setAuth } = useAuth()
  const axiosPrivate = useAxiosPrivate()

  const logout = async () => {
    let token = localStorage.getItem('token')
    try {
      await axiosPrivate.post("staff-hub/logout", {token: token}).then(function()
      {
        setAuth({})
        localStorage.removeItem("token")
        localStorage.removeItem("login")
      }).catch(error => {
        console.error(error)
      })
    } catch (error) {
      console.error(error)
    }
  }

  return logout
}

export default useLogout
