import axios from '../api/axios'
import useAuth from './useAuth'

function useRefreshData() {
  const { auth, setAuth } = useAuth()

  const refresh = async () => {
    const token = localStorage.getItem('token')
    const currentPolicy = JSON.parse(localStorage.getItem('currentPolicy'))
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    if (token) {
      const response = await axios.get('user', config)
      setAuth((prev) => {
        return { ...prev, token, user: response.data.result.user, schools: response.data.result.schools, currentPolicy: currentPolicy }
      })
      return token
    }
  }
  return refresh
}

export default useRefreshData
