// themes.js
export const themes = {
  default: {
    primaryColor: '#2babe0',
    secondaryColor: '#2babe0',
    backgroundColor: '#2babe0',
    textColor: '#2babe0',
    backgroundImage: 'url(/images/wave.png)',
    logo: { url: '/images/sas-logo.png', alt: 'Schools Advisory Service Logo' },
    website: 'https://schooladvice.co.uk',
    privacy: 'https://schooladvice.co.uk/privacy',
    phone_number: { link: 'tel:01773814400', value: '01773814400' },
    email: 'mailto:sales@uk-sas.co.uk',
    staffAbsence: 'Staff Absence Policy',
    login: {
      title: 'One Portal for All Your Staff Absence and Wellbeing Needs',
      first: 'Realtime Claims and Payment Management',
      second: 'Comprehensive Wellbeing Resources and Tools',
      third: 'Complete Control Over Staff Data',
    },
    footer: {
      top: `
      <div className="mt-4 text-xs">
                  <p className="text-gray-600 font-medium">
                    Trigg House, 11 Maisies Way, South Normanton, Derbyshire, DE55
                    2DS
                  </p>
                <div>
                    <p className="text-gray-600 font-medium">
                      Schools Advisory Service | Registered in England No.
                      03475198 | Authorised and Regulated by the Financial Conduct
                      Authority (Reg No. 309701)
                    </p>
                </div>
              </div>
      `,
      bottom: '&copy; 2024 Schools Advisory Service. All rights reserved.',
    },
  },
  schoolsMutual: {
    primaryColor: '#8CCB94',
    secondaryColor: '#8CCB94',
    backgroundColor: '#8CCB94',
    textColor: '#8CCB94',
    backgroundImage: 'url(/images/wave-mutual.png)',
    logo: {
      url: '/images/schools-mutual-logo.png',
      alt: 'Schools Mutual Logo',
    },
    website: 'https://schoolsmutual.co.uk',
    privacy: 'https://schoolsmutual.co.uk/privacy',
    email: 'mailto:enquiries@schoolsmutual.co.uk',
    phone_number: { link: 'tel:01773851079', value: '01773851079' },
    staffAbsence: 'Staff Absence Agreement',
    login: {
      title: 'One Portal for All Your Staff Absence and Wellbeing Needs',
      first: 'Realtime Payment Request Management',
      second: 'Comprehensive Wellbeing Resources and Tools',
      third: 'Complete Control Over Staff Data',
    },
    footer: {
      top: `
        <div className="mt-4 text-xs">
                  <p className="text-gray-600 font-medium">
                  Schools Mutual, a trading name of SAS Mutual CIC (12499719) a: Trigg House, 11 Maisies Way, South Normanton, DE55 2DS. ICO License No: ZA757213
                  </p>
              </div>
      `,
      bottom:
        '© Schools Mutual, a trading name of SAS Mutual CIC – All Rights Reserved',
    },
  },
}
