/* eslint-disable */
import React, { useRef, useState, useEffect } from 'react'
import {
  faCircleExclamation,
  faCheck,
  faTimes,
  faArrowRight,
  faChevronRight,
  faEnvelope,
  faPhone,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTheme } from '../context/ThemeProvider'

import { redirect, useNavigate, useSearchParams } from 'react-router-dom'

import axios from '../api/axios'

const EMAIL_REGEX =
  /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,12})$/
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/
const RESET_URL = 'v2/staff-hub/reset-password'
const SCHOOLS_URL = 'v2/staff-hub/get-schools-for-contact'
const GET_EMAIL = 'v2/staff-hub/get-email-from-token'

const ResetPassword = () => {
  let [searchParams] = useSearchParams()
  const theme = useTheme()
  const style = {
    backgroundColor: theme.backgroundColor,
    color: theme.textColor,
  }

  const navigate = useNavigate()

  const passwordRef = useRef()
  const errRef = useRef()

  const [email, setEmail] = useState('')
  const [validEmail, setValidEmail] = useState(false)

  const [pwd, setPwd] = useState('')
  const [pwdStarted, setPwdStarted] = useState(false)
  const [validPwd, setValidPwd] = useState(false)
  const [pwdFocus, setPwdFocus] = useState(false)

  const [matchPwd, setMatchPwd] = useState('')
  const [matchPwdStarted, setMatchPwdStarted] = useState('')
  const [validMatch, setValidMatch] = useState(false)
  const [matchFocus, setMatchFocus] = useState(false)

  const [schools, setSchools] = useState([])
  const [selectedSchools, setSelectedSchools] = useState([])
  const [validSchools, setValidSchools] = useState(false)

  const [errMsg, setErrMsg] = useState('')
  const [error, setError] = useState(false)

  const token = searchParams.get('token')

  const getEmailAndSchools = (token) => {
    axios
      .get(GET_EMAIL, {
        params: {
          token: token,
          type: 'password_reset',
        },
      })
      .then((emailResponse) => {
        let repEmail = emailResponse.data.result.email
        setEmail(repEmail)

        axios
          .get(SCHOOLS_URL, {
            params: {
              contact_email: repEmail,
            },
          })
          .then((response) => {
            if (response.data.result.length == 0) {
              navigate('/login', {
                state: {
                  messageType: 'error',
                  message: 'You do not have permission to access this page',
                },
              })
            }

            setSchools(response.data.result)
          })
      })

    const result = EMAIL_REGEX.test(email)
    setValidEmail(result)
    setErrMsg('')
  }

  useEffect(() => {
    if (!token) {
      navigate('/password/forgot', { replace: true })
    }

    getEmailAndSchools(token)
  }, [token])

  useEffect(() => {
    const result = PWD_REGEX.test(pwd)
    setValidPwd(result)
    const match = pwd === matchPwd
    setValidMatch(match)
    setErrMsg('')
  }, [pwd, matchPwd])

  const toggleSelection = (event) => {
    const { value, checked } = event.target
    setSelectedSchools((prevSelectedItems) => {
      if (checked) {
        return [...prevSelectedItems, value]
      } else {
        return prevSelectedItems.filter((item) => item !== value)
      }
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    const v1 = PWD_REGEX.test(pwd)
    if (!v1) {
      setErrMsg('Invalid Entry')
      return
    }

    try {
      const response = await axios.post(RESET_URL, {
        token,
        contact_email: email,
        contact_password: pwd,
        password_confirmation: matchPwd,
        contact_schools: selectedSchools,
      })
      setPwd('')

      if (response.data.success) {
        navigate('/', {
          replace: true,
          state: {
            messageType: 'success',
            message: 'Password successfully reset',
          },
        })
      }

      if (response.data.result.invalid_token) {
        setError(true)
      }
    } catch (error) {
      if (!error?.response) {
        setErrMsg('No Server Response')
      } else if (error.response?.status === 422) {
        setErrMsg('The email provided already exists.')
      } else {
        setErrMsg('Error Occurred. Could not Reset Password.')
      }
      errRef.current.focus()
    }
  }

  return (
    <>
      {error ? (
        <>
          <h3 className="text-2xl sm:text-3xl md:text-5xl font-bold text-white mb-8 sm:mb-16">
            Token Expired
          </h3>

          {errMsg && (
            <div className="rounded-md bg-red-50 p-4 mb-4">
              <div className="flex">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  aria-hidden="true"
                  className="h-5 w-5"
                  fill="#dc2626"
                >
                  <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" />
                </svg>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-red-800" ref={errRef}>
                    {errMsg}
                  </h3>
                </div>
              </div>
            </div>
          )}

          <div className="w-full max-w-4xl flex justify-center flex-col-reverse lg:flex-row">
            <div className="w-full lg:w-7/12 bg-white shadow-lg rounded-md overflow-hidden p-6 lg:p-12">
              <div className="text-center mx-auto pb-4">
                <FontAwesomeIcon
                  icon={faCircleExclamation}
                  className="h-10 w-10 text-blue-700"
                />
              </div>
              <p className="text-center w-full">
                We could not reset your password as the token used has expired.
                Please go to{' '}
                <span
                  className=" cursor-pointer"
                  style={{ color: style.color }}
                  onClick={() =>
                    navigate('/password/forgot', { replace: true })
                  }
                >
                  Forgot Password
                </span>{' '}
                and try again.
              </p>
            </div>
          </div>
        </>
      ) : (
        <>
          <h3 className="text-2xl sm:text-3xl md:text-5xl font-bold text-white mb-8 sm:mb-16">
            Forgot your Password?
          </h3>
          <p
            ref={errRef}
            className={errMsg ? 'errmsg' : 'offscreen'}
            aria-live="assertive"
          >
            {errMsg}
          </p>
          <div className="w-full max-w-4xl flex justify-center flex-col-reverse lg:flex-row">
            <div className="w-full lg:w-7/12 bg-white shadow-lg rounded-md overflow-hidden">
              <div className="w-full p-6 lg:p-12">
                <form
                  onSubmit={handleSubmit}
                  method="POST"
                  className="space-y-4 sm:space-y-6"
                >
                  <div className="relative">
                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-slate-500"
                      >
                        Email address
                      </label>
                      <div>
                        <input
                          type="email"
                          id="email"
                          value={email}
                          required
                          readOnly
                          aria-describedby="emailnote"
                          placeholder="Email"
                          disabled
                          className="text-base appearance-none border rounded w-full py-2 px-3 text-gray-700 "
                        />
                      </div>
                    </div>
                  </div>

                  <div className="relative">
                    <div>
                      <label
                        htmlFor="pwd"
                        className="block text-sm font-medium text-slate-500"
                      >
                        Password
                      </label>
                      <div>
                        <input
                          type="password"
                          id="pwd"
                          ref={passwordRef}
                          onChange={(e) => {
                            setPwd(e.target.value)
                            setPwdStarted(true)
                          }}
                          required
                          aria-invalid={validPwd ? 'false' : 'true'}
                          aria-describedby="pwdnote"
                          placeholder="Password"
                          onFocus={() => setPwdFocus(true)}
                          onBlur={() => setPwdFocus(false)}
                          className={`text-base appearance-none border rounded w-full py-2 px-3 text-gray-700 ${
                            pwd.length > 0 || pwdStarted
                              ? validPwd
                                ? ''
                                : 'border-red-500'
                              : ''
                          } `}
                        />
                        {pwd.length > 0 || pwdStarted ? (
                          validPwd ? (
                            ''
                          ) : (
                            <span className="text-sm text-red-500">
                              Password does not meet criteria
                            </span>
                          )
                        ) : (
                          ''
                        )}
                      </div>
                    </div>

                    <span
                      className={
                        pwd && validPwd
                          ? 'valid input-check !right-[0.5rem] !top-[30px]'
                          : 'hide input-check !right-[0.5rem] !top-[30px]'
                      }
                    >
                      <FontAwesomeIcon icon={faCheck} />
                    </span>
                    <span
                      className={
                        pwd && validPwd
                          ? 'hide input-check !right-[0.5rem] !top-[30px]'
                          : 'invalid input-check !right-[0.5rem] !top-[30px]'
                      }
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </span>
                    <p
                      id="pwdnote"
                      className={
                        pwdFocus && pwd && !validPwd
                          ? 'instructions'
                          : 'offscreen'
                      }
                    >
                      <FontAwesomeIcon icon={faCircleExclamation} />
                      8 to 24 characters. <br />
                      Must include uppercase and lowercase letters, a number and
                      a special character. <br />
                      Allowed special characters:
                      <span aria-label="exclamation mark"> !</span>
                      <span aria-label="at symbol"> @</span>
                      <span aria-label="hashtag"> #</span>
                      <span aria-label="dollar sign"> $</span>
                      <span aria-label="percent"> %</span>
                    </p>
                  </div>

                  <div className="relative">
                    <div>
                      <label
                        htmlFor="confirm_pwd"
                        className="block text-sm font-medium text-slate-500"
                      >
                        Confirm Password
                      </label>
                      <div>
                        <input
                          type="password"
                          id="confirm_pwd"
                          onChange={(e) => {
                            setMatchPwd(e.target.value)
                            setMatchPwdStarted(true)
                          }}
                          required
                          aria-invalid={validMatch ? 'false' : 'true'}
                          aria-describedby="confirmnote"
                          placeholder="Confirm Password"
                          onFocus={() => setMatchFocus(true)}
                          onBlur={() => setMatchFocus(false)}
                          className={`text-base appearance-none border rounded w-full py-2 px-3 text-gray-700 ${
                            matchPwd.length > 0 || matchPwdStarted
                              ? validPwd
                                ? ''
                                : 'border-red-500'
                              : ''
                          } `}
                        />
                        {matchPwd.length > 0 || matchPwdStarted ? (
                          validMatch ? (
                            ''
                          ) : (
                            <span className="text-sm text-red-500">
                              Password does not match the first password input.
                            </span>
                          )
                        ) : (
                          ''
                        )}
                      </div>
                    </div>

                    <span
                      className={
                        matchPwd && validMatch
                          ? 'valid input-check !right-[0.5rem] !top-[30px]'
                          : 'hide input-check !right-[0.5rem] !top-[30px]'
                      }
                    >
                      <FontAwesomeIcon icon={faCheck} />
                    </span>
                    <span
                      className={
                        matchPwd && validMatch
                          ? 'hide input-check !right-[0.5rem] !top-[30px]'
                          : 'invalid input-check !right-[0.5rem] !top-[30px]'
                      }
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </span>
                    <p
                      id="confirmnote"
                      className={
                        matchFocus && matchPwd && !validMatch
                          ? 'instructions'
                          : 'offscreen'
                      }
                    >
                      <FontAwesomeIcon icon={faCircleExclamation} />
                      Must match the first password input.
                    </p>
                  </div>

                  <div className="relative">
                    <div>
                      <label
                        htmlFor="schoolselect"
                        className="block text-sm font-medium text-slate-500 mb-2"
                      >
                        Select Schools
                        {selectedSchools.length > 0 ? (
                          <span className="valid ml-2 input-check !right-[0.5rem] !top-[30px]">
                            <FontAwesomeIcon icon={faCheck} />
                          </span>
                        ) : (
                          <span className="invalid ml-2 input-check !right-[0.5rem] !top-[30px]">
                            <FontAwesomeIcon icon={faTimes} />
                          </span>
                        )}
                      </label>
                      <p className="mb-4 text-sm">
                        Please select the schools that you wish to set the
                        password for.
                      </p>
                      <div>
                        {schools?.map((item) => {
                          return (
                            <div
                              className="flex text-sm align-center mr-2"
                              key={item.id}
                            >
                              <input
                                type="checkbox"
                                value={item.id}
                                onChange={toggleSelection}
                                className="form-check-label font-bold ml-4 mr-2"
                              />
                              <label className="pt-[2px]">{item.name}</label>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="button"
                      style={{
                        borderColor:
                          !validPwd ||
                          !validMatch ||
                          selectedSchools.length == 0
                            ? 'gray'
                            : style.backgroundColor,
                        cursor:
                          !validPwd ||
                          !validMatch ||
                          selectedSchools.length == 0
                            ? 'not-allowed'
                            : 'pointer',
                      }}
                    >
                      <span
                        style={{
                          color:
                            !validPwd ||
                            !validMatch ||
                            selectedSchools.length == 0
                              ? 'gray'
                              : style.color,
                        }}
                      >
                        Reset Password
                      </span>
                      <span
                        style={{
                          backgroundColor:
                            !validPwd ||
                            !validMatch ||
                            selectedSchools.length == 0
                              ? 'gray'
                              : style.backgroundColor,
                        }}
                      >
                        <FontAwesomeIcon icon={faArrowRight} />
                      </span>
                    </button>
                  </div>

                  <div className="text-sm flex">
                    <a
                      href="/login"
                      className="mt-1.5 text-xs"
                      style={{ color: style.color }}
                    >
                      Back to Login
                    </a>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default ResetPassword
